import { useState, useEffect } from "react";
import Link from "next/link";
import { Grid, Column, TextInput, Button, Loading } from "@carbon/react";
import { CheckmarkFilled, ErrorFilled } from "@carbon/icons-react";
import styles from "./Newsletter.module.scss";
import posthog from "posthog-js";

export default function Newsletter() {
  const [email, setEmail] = useState("");
  const [honeypot, setHoneypot] = useState("");
  const [status, setStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [startTime, setStartTime] = useState(Date.now());

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (honeypot || Date.now() - startTime < 7000) {
      setStatus("error");
      return;
    }

    setIsLoading(true);
    setStatus("");

    try {
      const response = await fetch("https://api.useplunk.com/v1/track", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer pk_14cec64864a553d3ba7e0a9f908002571d9b69d6195fb994",
        },
        body: JSON.stringify({
          event: "newsletter_subscription",
          email,
          subscribed: true,
          data: { source: "website" },
        }),
      });

      const data = await response.json();

      if (data.success) {
        setStatus("success");
        setEmail("");

        posthog.capture("newsletter_subscribe_success", { email: email });
      } else {
        setStatus("error");

        posthog.capture("newsletter_subscribe_error", {
          error: "API response unsuccessful",
        });
      }
    } catch (error) {
      setStatus("error");
      posthog.capture("newsletter_subscribe_error", {
        error: error instanceof Error ? error.message : "Unknown error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Grid>
        <Column sm={4} md={8} lg={16}>
          <div className={styles.container}>
            <h2 className={styles.title}>
              Want product news and updates? Sign up for our newsletter.
            </h2>

            <div className={styles.formContainer}>
              <form onSubmit={handleSubmit}>
                <div className={styles.inputGroup}>
                  <TextInput
                    id="email-address"
                    labelText="Email address"
                    hideLabel
                    type="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                    size="lg"
                    disabled={isLoading}
                  />
                  <input
                    type="text"
                    name="website"
                    style={{ display: "none" }}
                    tabIndex={-1}
                    autoComplete="off"
                    value={honeypot}
                    onChange={(e) => setHoneypot(e.target.value)}
                    aria-hidden="true"
                  />
                  <Button type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <Loading withOverlay={false} small />
                    ) : (
                      "Subscribe"
                    )}
                  </Button>
                </div>

                <div className={styles.message}>
                  {status === "success" && (
                    <div className={styles.messageSuccess}>
                      <CheckmarkFilled size={16} /> Thanks for subscribing!
                    </div>
                  )}
                  {status === "error" && (
                    <div className={styles.messageError}>
                      <ErrorFilled size={16} /> Something went wrong. Please try
                      again.
                    </div>
                  )}
                  {!status && (
                    <div className={styles.messageInfo}>
                      We care about your data. Read our{" "}
                      <Link href="/privacy">privacy policy</Link>.
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </Column>
      </Grid>
    </div>
  );
}
