import React from "react";
import styled from "styled-components";
import { useTheme } from "@/apx/Theme";

interface ImageShowcaseProps {
  leftImageLight: string;
  leftImageDark: string;
  centerImageLight: string;
  centerImageDark: string;
  rightImageLight: string;
  rightImageDark: string;
}

const ShowcaseContainer = styled.div`
  display: none;
  position: relative;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 700px;
    margin: 0 auto;
    align-items: center;
    margin-top: -40px;
  }
`;

const ImageContainer = styled.div<{
  isCenter?: boolean;
  position?: "left" | "center" | "right";
}>`
  position: absolute;
  width: ${(props) => (props.isCenter ? "50%" : "40%")};
  height: ${(props) => (props.isCenter ? "100%" : "85%")};
  z-index: ${(props) => (props.isCenter ? 3 : 1)};
  left: ${(props) => {
    if (props.position === "left") return "35%";
    if (props.position === "center") return "50%";
    if (props.position === "right") return "65%";
    return "auto";
  }};
  overflow: hidden;
  transition: all 0.5s ease;
  transform: ${(props) => {
    if (props.position === "left")
      return "translateX(-50%) perspective(900px) rotateX(38deg)";
    if (props.position === "center")
      return "translateX(-50%) perspective(1800px) rotateX(38deg)";
    if (props.position === "right")
      return "translateX(-50%) perspective(900px) rotateX(38deg)";
  }};

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      2deg,
      rgba(255, 255, 255, 1) 18%,
      rgba(255, 255, 255, 0) 64%
    );

    mix-blend-mode: overlay;
    transition: opacity 0.5s ease;
  }

  &:hover::after {
    opacity: 0;
  }

  &:hover IMG {
    transform: scale(1);
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  transform: scale(1.4);
  transition: transform 0.5s ease;
`;

const ImageShowcase: React.FC<ImageShowcaseProps> = ({
  leftImageLight,
  leftImageDark,
  centerImageLight,
  centerImageDark,
  rightImageLight,
  rightImageDark,
}) => {
  const { isDark } = useTheme();

  const leftImage = isDark ? leftImageDark : leftImageLight;
  const centerImage = isDark ? centerImageDark : centerImageLight;
  const rightImage = isDark ? rightImageDark : rightImageLight;

  return (
    <ShowcaseContainer>
      <ImageContainer position="left">
        <StyledImage src={leftImage} alt="Showcase left" />
      </ImageContainer>

      <ImageContainer isCenter position="center">
        <StyledImage src={centerImage} alt="Showcase center" />
      </ImageContainer>

      <ImageContainer position="right">
        <StyledImage src={rightImage} alt="Showcase right" />
      </ImageContainer>
    </ShowcaseContainer>
  );
};

export default ImageShowcase;
