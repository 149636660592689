import { useTheme } from "@/apx/Theme";
import C4DImageItem from "@carbon/ibmdotcom-web-components/es/components-react/image/image-item";
import React, { useEffect, useState } from "react";

interface C4DImageItemProps {
  lightSrc: string;
  darkSrc: string;
  media: string;
  alt?: string;
}

export default function CarbonImage({
  lightSrc,
  darkSrc,
  media,
  alt,
}: C4DImageItemProps) {
  const { isDark } = useTheme();

  const [srcset, setSrcset] = useState<string>(lightSrc);

  useEffect(() => {
    setSrcset(isDark ? darkSrc : lightSrc);
  }, [isDark]);

  return <C4DImageItem key={srcset} media={media} srcset={srcset} alt={alt} />;
}
